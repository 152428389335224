export function toFloat(value: string | number | undefined | null): number {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  } else {
    return 0;
  }
}

export function toInt(value: string | number | undefined | null): number {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    const parsed = parseInt(value);
    return isNaN(parsed) ? 0 : parsed;
  } else {
    return 0;
  }
}

export const getBase64 = async (file: File) => {
  return new Promise<string>((result, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      result(reader.result as string);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};