import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "../../theme";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAddMessageMutation } from "../../apis/coursesAPI";
import { selector } from "../../store";
import { getOrganisation } from "../../slices/organisationSlice";
import { ViewContainer } from "./viewContainer";

export const MessageContent = (props: {
  content: string;
  edit?: boolean;
  height?: string | number;
  width?: string | number;
  onSetReady?: () => void;
  onValueChanged: (val: string) => void;
  password?: string;
}) => {
  const { onSetReady } = props;
  useEffect(() => onSetReady && onSetReady(), [onSetReady]);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [sentMessage, setSentMessage] = useState(false);
  const [includeName, setIncludeName] = useState(true);
  const [allowAnonymousMessages, setAllowAnonymousMessages] = useState(
    props.content
  );
  const [searchParams] = useSearchParams();
  const courseId = useMemo(() => searchParams.get("courseId"), [searchParams]);
  const userId = useMemo(() => searchParams.get("userId"), [searchParams]);
  const apiKey = useMemo(() => searchParams.get("apiKey"), [searchParams]);
  const org = selector((state) => getOrganisation(state));

  const organisationId = useMemo(
    () => searchParams.get("organisationId"),
    [searchParams]
  );

  const [
    addMessage,
    {
      isLoading: addMessageLoading,
      isSuccess: messageSuccess,
      isError: messageError,
    },
  ] = useAddMessageMutation();

  // Get name from organisation info, to identify message sender.
  // If name is not present, return email.
  // If email is also not present, return empty.
  const getName = () => {
    if (org?.firstName && org.lastName) {
      return org?.firstName + " " + org.lastName;
    } else if (org?.email) return org?.email;
    else return " ";
  };

  return (
    <ViewContainer loading={addMessageLoading}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        minHeight={theme.spacing(2)}
        height={props.height ? props.height : "100%"}
        width={props.width ? props.width : "100%"}
        minWidth={100}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <TextField
          label={t("message")}
          margin="normal"
          sx={{ input: { color: "black" } }}
          style={{ minWidth: 100, width: "100%" }}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
        {allowAnonymousMessages === "true" && (
          // Select if the messages is sent anonymously or with the sender's name
          <FormControlLabel
            control={
              <Checkbox
                value={includeName}
                checked={includeName}
                aria-label={t("includeName")}
                onChange={(event) => setIncludeName(event.target.checked)}
              ></Checkbox>
            }
            label={t("includeName")}
          />
        )}
        <Button
          disabled={props.edit || addMessageLoading}
          variant={"contained"}
          onClick={() => {
            addMessage({
              message: {
                message: message,
                sender: includeName ? getName() : undefined,
              },
              organisationId: organisationId || org?.organisationId || "",
              courseId: courseId || "",
              userId: userId || "",
              password: props.password || "",
              apiKey: apiKey || "",
            });
            setSentMessage(true);
          }}
        >
          {t("send")}
        </Button>
        {props.edit && (
          // Enable allowing anonymous messages.
          <FormControlLabel
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            control={
              <Checkbox
                value={allowAnonymousMessages === "true"}
                checked={allowAnonymousMessages === "true"}
                aria-label={t("allowIncludeName")}
                onChange={(event) => {
                  props.onValueChanged(event.target.checked.toString());
                  setAllowAnonymousMessages(event.target.checked.toString());
                }}
              ></Checkbox>
            }
            label={t("allowIncludeName")}
          />
        )}
      </Box>

      <Modal
        open={!!sentMessage && !addMessageLoading}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            padding: theme.spacing(3),
          }}
        >
          <Typography>
            {messageError ? t("messageError") : t("sentMessage")}
          </Typography>
          <Button
            variant="text"
            style={{ paddingTop: theme.spacing(2) }}
            onClick={() => {
              setSentMessage(false);
              setMessage("");
            }}
          >
            {t("ok")}
          </Button>
        </Card>
      </Modal>
    </ViewContainer>
  );
};
