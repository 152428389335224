import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { t } from "i18next";
import { IContentType, ISlideContent } from "@backend/model/slide";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import {
  ContentCopy,
  DeleteOutlined,
  EditOutlined,
  RemoveOutlined,
  AspectRatio,
  PlayArrow,
  MailOutline,
} from "@mui/icons-material";
import { TextContent } from "./slideTextContent";
import { ImageContent } from "./slideImageContent";
import { VideoContent } from "./slideVideoContent";
import { QuizContent } from "./slideQuizContent";
import { theme } from "../../theme";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import colors from "../../colors";
import { MessageContent } from "./slideMessageContent";
import { GalleryModal } from "./modals/galleryModal";
import {
  useListImagesQuery,
  useListOrganisationImagesQuery,
} from "../../apis/coursesAPI";
import { selector } from "../../store";
import { getOrganisation } from "../../slices/organisationSlice";
import { GalleryContent } from "./slideGalleryContent";

interface ISlideContentSelectorProps {
  setContent: (
    index: number,
    content: string,
    contentType?: string,
    metadata?: string
  ) => void;
  content: ISlideContent;
  index: number;
  edit?: boolean;
  thumbnail?: boolean;
  onSetReady: (index: number) => void;
  organisationId: string;
  password?: string;
}

export const SlideContentSelector = (props: ISlideContentSelectorProps) => {
  const { index, setContent, onSetReady, content, organisationId } = props;
  const setReadyCallback = useCallback(() => {
    onSetReady(index);
  }, [index, onSetReady]);

  const [galleryAutoOpenImage, setGalleryAutoOpenImage] = useState(false);
  useEffect(() => {
    if (galleryAutoOpenImage) {
      setGalleryAutoOpenImage(false);
    }
  }, [galleryAutoOpenImage]);

  const [autoOpenImage, setAutoOpenImage] = useState(false);
  useEffect(() => {
    if (autoOpenImage) setAutoOpenImage(false);
  }, [autoOpenImage]);

  const onFillModeChangedCallback = useCallback(
    (fillMode: string) => {
      console.log(
        index,
        content.data,
        content.contentType,
        JSON.stringify({ fillMode })
      );
      setContent(
        index,
        content.data,
        content.contentType,
        JSON.stringify({ fillMode })
      );
    },
    [content, index, setContent]
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);

  const {
    data: galleryImages,
    isFetching: imagesFetching,
    isLoading: imagesLoading,
    refetch: refetchGalleryImages,
  } = useListImagesQuery(organisationId, { skip: !props.edit });

  const {
    data: organisationGalleryImages,
    isFetching: organisationGalleryImagesFetching,
    isLoading: organisationGalleryImagesLoading,
  } = useListOrganisationImagesQuery(organisationId, { skip: !props.edit });

  const actions: Array<{
    icon: ReactNode;
    onClick: () => void;
  }> = [];
  if (props.content && props.edit && props.content.contentType !== "unknown") {
    actions.push({
      icon: <DeleteOutlined style={{ zIndex: 11 }} />,
      onClick: () => setShowDeleteModal(true),
    });
  }

  if (
    props.content &&
    props.edit &&
    (props.content.contentType === "image" ||
      props.content.contentType === "galleryImage")
  ) {
    actions.push({
      icon: <EditOutlined />,
      onClick: () => setShowGalleryModal(true),
    });
    actions.push({
      icon: <AspectRatio />,
      onClick: () => {
        const metadata = JSON.parse(content.metadata || "{}");
        onFillModeChangedCallback(
          metadata.fillMode === "cover" ? "contain" : "cover"
        );
      },
    });
  }

  // Image height is conditional on contentType. Images require height to be defined, text scales the content height automatically.
  const imageHeight = () => {
    if(props.edit)
      return("100%")
    else if (props.content.contentType === "image" ||
      props.content.contentType === "galleryImage" ||
      props.content.contentType == "video"
    )
      return "100%";
    else return undefined;
  };

  return (
    <Box flex={1} display={"flex"} height={imageHeight()} position={"relative"}>
      {/* Confirmation modal for deleting slide content */}
      <Modal
        open={showDeleteModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            padding: theme.spacing(3),
            background: colors.backgroundSecondary,
          }}
        >
          <Typography>{t("deletePartConfirmation")}</Typography>
          <Button
            variant="text"
            style={{ paddingTop: theme.spacing(2) }}
            onClick={() => {
              setContent(index, "", "unknown", undefined); // Set content to empty
              setShowDeleteModal(false);
            }}
          >
            {t("ok")}
          </Button>
          <Button
            variant="text"
            style={{ paddingTop: theme.spacing(2) }}
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            {t("cancel")}
          </Button>
        </Card>
      </Modal>

      {/* Gallery modal */}

      <GalleryModal
        galleryImages={galleryImages || []}
        ownImages={organisationGalleryImages || []}
        addGalleryImage={async (image) => {
          setContent(index, image.imageUrl, "galleryImage"); // Save url to content.
        }}
        isVisible={showGalleryModal}
        setVisible={(val) => setShowGalleryModal(val)}
        addImage={() => {
          setContent(index, "", "image", undefined);
          setAutoOpenImage(true);
          setShowGalleryModal(false);
        }}
        addImageToGallery={() => {
          setContent(index, "", "galleryImage", undefined);
          setGalleryAutoOpenImage(true);
          setShowGalleryModal(false);
        }}
      />

      {/* Edit: */}
      {props.content.contentType === "unknown" && props.edit && (
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            startIcon={<SubjectOutlinedIcon />}
            style={{ width: 150, height: 40, marginBottom: theme.spacing(1) }}
            variant="contained"
            onClick={() => setContent(index, "", "text")}
          >
            {t("textContent")}
          </Button>
          <Button
            startIcon={<ImageOutlinedIcon />}
            style={{ width: 150, height: 40, marginBottom: theme.spacing(1) }}
            variant="contained"
            onClick={() => {
              setShowGalleryModal(true);
              setContent(index, "", "image");
            }}
          >
            {t("imageContent")}
          </Button>
          <Button
            startIcon={<VideocamOutlinedIcon />}
            style={{ width: 150, height: 40, marginBottom: theme.spacing(1) }}
            variant="contained"
            onClick={() => setContent(index, "", "video")}
          >
            {t("videoContent")}
          </Button>
          <Button
            startIcon={<QuizOutlinedIcon />}
            style={{ width: 150, height: 40, marginBottom: theme.spacing(1) }}
            variant="contained"
            onClick={() => setContent(index, "", "quiz")}
          >
            {t("quizContent")}
          </Button>
          <Button
            startIcon={<MailOutline />}
            style={{ width: 150, height: 40, marginBottom: theme.spacing(1) }}
            variant="contained"
            onClick={() => setContent(index, "", "message")}
          >
            {t("messageContent")}
          </Button>
        </Box>
      )}
      {
        // If slide doesn't have content, show an empty text slide instead.
        props.content.contentType === "unknown" && !props.edit && (
          <TextContent
            content={""}
            edit={props.edit}
            onValueChanged={() => {}}
            onSetReady={setReadyCallback}
          />
        )
      }
      {props.content.contentType === "text" && (
        <TextContent
          content={props.content.data}
          edit={props.edit}
          onValueChanged={(val) => setContent(index, val)}
          onSetReady={setReadyCallback}
          thumbnailSlide={props.thumbnail}
        />
      )}
      {props.content.contentType === "image" && (
        <ImageContent
          content={props.content.data}
          edit={props.edit}
          onValueChanged={(val) => {
            setContent(index, val);
            console.log("image ", index, " changed:");
          }}
          onFillModeChanged={onFillModeChangedCallback}
          metadataStr={props.content.metadata}
          onSetReady={setReadyCallback}
          autoOpen={autoOpenImage}
        />
      )}
      {props.content.contentType === "galleryImage" && (
        <GalleryContent
          content={props.content.data}
          edit={props.edit}
          onValueChanged={(val) => {
            setContent(index, val, "galleryImage");
          }}
          onFillModeChanged={onFillModeChangedCallback}
          metadataStr={props.content.metadata}
          onSetReady={setReadyCallback}
          autoOpen={galleryAutoOpenImage}
        />
      )}
      {props.content.contentType === "video" &&
        (props.thumbnail ? (
          <PlayArrow
            sx={{
              alignSelf: "center",
              width: "50%",
              height: "50%",
              marginLeft: 18,
            }}
          />
        ) : (
          <VideoContent
            content={props.content.data}
            edit={props.edit}
            onValueChanged={(val) => setContent(index, val)}
            onSetReady={setReadyCallback}
          />
        ))}
      {props.content.contentType === "quiz" && (
        <QuizContent
          content={props.content.data}
          edit={props.edit}
          onValueChanged={(val) => {
            setContent(index, val);
          }}
          thumbnail={props.thumbnail}
          index={props.index}
          onSetReady={setReadyCallback}
        />
      )}
      {props.content.contentType === "message" && (
        <MessageContent
          content={props.content.data}
          edit={props.edit}
          onSetReady={setReadyCallback}
          onValueChanged={(val) => setContent(index, val)}
          password={props.password}
        />
      )}
      <Box
        display={"flex"}
        flexDirection={"row-reverse"}
        position={"absolute"}
        top={theme.spacing(2)}
        right={theme.spacing(1)}
      >
        {actions.map((a) => (
          <IconButton
            onClick={a.onClick}
            style={{
              borderRadius: 30,
              backgroundColor: "rgba(0,0,0,0.6)",
              marginRight: theme.spacing(1),
            }}
          >
            {a.icon}
          </IconButton>
        ))}
      </Box>
    </Box>
  );
};
