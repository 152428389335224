import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../theme";
import { TextContent } from "./slideTextContent";
import { useTranslation } from "react-i18next";
import { mobileWidth } from "../../styleUtils";
import colors from "../../colors";
import { DeleteOutline } from "@mui/icons-material";
import { toInt } from "../../utils/common";

interface QuizContentProps {
  content: string;
  edit?: boolean;
  onValueChanged?: (val: string) => void;
  onSetReady: () => void;
  index: number;
  thumbnail?: boolean;
}

export const QuizContent = (props: QuizContentProps) => {
  const [result, setResult] = useState<string>();
  const [passed, setPassed] = useState(false);
  const { t } = useTranslation();
  const narrowScreen = useMediaQuery(mobileWidth);
  const [refreshQuestions, setRefreshQuestions] = useState<number>(0);

  const { edit, onValueChanged, onSetReady, content } = props;

  const contentData = useMemo(
    () => (content ? JSON.parse(content) : {}),
    [content]
  );

  const [quizQuestions, setQuizQuestions] = useState<string[] | undefined>(
    contentData.questions ? [...contentData.questions] : undefined
  );
  const [quizAnswers, setQuizAnswers] = useState<Array<string[]> | undefined>(
    contentData.answers ? [...contentData.answers] : []
  );
  const [correctAnswers, setCorrectAnswers] = useState<number[] | undefined>(
    contentData.correctAnswers ? [...contentData.correctAnswers] : undefined
  );

  const [answers, setAnswers] = useState<Array<number | undefined>>(
    props.edit && contentData.correctAnswers
      ? contentData.correctAnswers
      : Array.apply(null, Array(contentData.questions?.length)).map(
          () => undefined
        )
  );

  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(
    toInt(contentData.numberOfQuestions) || contentData.questions?.length || 0
  );

  const [numberOfCorrectAnswers, setNumberOfCorrectAnswers] = useState<number>(
    toInt(contentData.numberOfCorrectAnswers) ||
      contentData.questions?.length ||
      0
  );

  useEffect(() => {
    const newContentData = JSON.stringify({
      questions: quizQuestions,
      answers: quizAnswers,
      correctAnswers,
      numberOfQuestions: numberOfQuestions,
      numberOfCorrectAnswers: numberOfCorrectAnswers,
    });
    if (edit && onValueChanged && newContentData !== content) {
      onValueChanged(newContentData);
    }
  }, [
    quizAnswers,
    quizQuestions,
    correctAnswers,
    edit,
    onValueChanged,
    content,
    numberOfQuestions,
    numberOfCorrectAnswers,
  ]);

  const checkSingleSelectionMode = useCallback(() => {
    if (
      correctAnswers &&
      correctAnswers.length === 1 &&
      correctAnswers[0] === 1
    ) {
      return true;
    } else return false;
  }, [correctAnswers]);

  // Select a number of random questions determined in the quiz settings.
  const selectedQuizQuestions = useCallback(() => {
    let selectedQuestions: string[] = [];
    let selectedCorrectAnswers: number[] = [];
    let selectedAnswers: string[][] = [];

    //when editing, lets return all
    if (edit) {
      selectedQuestions = quizQuestions || [];
      selectedCorrectAnswers = correctAnswers || [];
      selectedAnswers = quizAnswers || [];
      return { selectedQuestions, selectedCorrectAnswers, selectedAnswers };
    }

    let questionsArray = [...(quizQuestions || [])];
    let correctAnswerArray = [...(correctAnswers || [])];
    let answersArray = quizAnswers
      ? JSON.parse(JSON.stringify(quizAnswers))
      : [];

    let iterations = numberOfQuestions || quizQuestions?.length || 0;

    for (let i = 0; i < iterations; i++) {
      let randNumber = Math.floor(Math.random() * questionsArray.length);

      // Add to selected array and remove from temp array, so there's no duplicates.
      selectedQuestions.push(questionsArray[randNumber]);
      questionsArray.splice(randNumber, 1);
      selectedCorrectAnswers.push(correctAnswerArray[randNumber]);
      correctAnswerArray.splice(randNumber, 1);
      selectedAnswers.push(answersArray[randNumber]);
      answersArray.splice(randNumber, 1);
    }

    // Return all values in an array
    return { selectedQuestions, selectedCorrectAnswers, selectedAnswers };
  }, [quizQuestions, quizAnswers, numberOfQuestions, correctAnswers, edit]);

  // Memoize selected questions, so they don't change unless specified.
  const { selectedQuestions, selectedCorrectAnswers, selectedAnswers } =
    useMemo(
      () => selectedQuizQuestions(),
      [selectedQuizQuestions, refreshQuestions]
    );

  // Required correct answers is numberOfCorrectAnswers or if not set, the number of answers.
  const requiredCorrectAnswers = numberOfCorrectAnswers || answers.length;

  const checkAnswers = useCallback(() => {
    let corrects = 0;
    for (let i = 0; i < selectedCorrectAnswers.length; i++)
      if (selectedCorrectAnswers && selectedCorrectAnswers[i] === answers[i])
        corrects++;

    if (corrects >= requiredCorrectAnswers) {
      onSetReady();
      setPassed(true);
    }
    setResult(`${corrects}/${selectedCorrectAnswers.length || 0}`);
  }, [answers, onSetReady, selectedCorrectAnswers, requiredCorrectAnswers]);

  // On a single checkbox screens, just checking the box is required to continue.
  const singleAnswerConfirmation = useCallback(() => {
    if (!props.edit) {
      if (checkSingleSelectionMode()) {
        props.onSetReady();
      }
    }
  }, [checkSingleSelectionMode, props]);

  if (result) {
    return (
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography
          marginBottom={theme.spacing(2)}
          fontWeight="bold"
          variant="h3"
        >
          {passed ? t("passed") : t("failed")}
        </Typography>
        <Typography fontWeight="bold" variant="h5">{`${t(
          "result"
        )}: ${result}`}</Typography>
        <Typography color={colors.textSecondary} variant="subtitle1">{`${t(
          "required"
        )}: ${requiredCorrectAnswers}/${
          numberOfQuestions || answers.length
        }`}</Typography>

        {!passed && (
          <Button
            style={{ marginTop: theme.spacing(2) }}
            variant="contained"
            onClick={() => {
              // Refresh selected questions when trying again.
              setRefreshQuestions(refreshQuestions + 1);
              setAnswers(
                Array.apply(null, Array(contentData.questions?.length)).map(
                  () => undefined
                )
              );
              setResult(undefined);
            }}
          >
            {t("tryAgain")}
          </Button>
        )}
      </Box>
    );
  }

  return (
    <Grid
      container
      flex={1}
      flexGrow={1}
      alignItems={"flex-start"}
      alignContent={"flex-start"}
      style={{
        overflowX: "hidden",
        overflowY: props.thumbnail ? "hidden" : "auto",
        padding: theme.spacing(1),
      }}
    >
      {selectedQuestions &&
        selectedQuestions.map((q, i) => (
          <>
            <Grid
              key={`quiz-${props.index}-grid-${i}`}
              item
              xs={12}
              display={"flex"}
              style={{
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
                marginBottom: theme.spacing(1),
              }}
            >
              <TextContent
                content={q}
                key={`quiz-${props.index}-question-${i}`}
                multiline
                minimalEditor
                edit={props.edit}
                onValueChanged={(val) => {
                  const newQuestions = [...selectedQuestions];
                  newQuestions[i] = val;
                  setQuizQuestions(newQuestions);
                }}
              />
              {props.edit && (
                <Button
                  onClick={() => {
                    const newQuestions = [...selectedQuestions].splice(i, 1);
                    setQuizQuestions(newQuestions);
                    const newAnswers = [...answers].splice(i, 1);
                    setAnswers(newAnswers);
                  }}
                >
                  <DeleteOutline />
                </Button>
              )}
            </Grid>
            <Grid
              key={`quiz-${props.index}-answers-${i}`}
              item
              xs={12}
              flex={1}
              flexDirection={"column"}
              display={"flex"}
              style={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                justifyItems: "flex-start",
                marginBottom: theme.spacing(1),
              }}
            >
              {selectedAnswers &&
                selectedAnswers[i].map((a, j) => {
                  return (
                    <Box
                      key={`quiz-continer-${props.index}-question-${i}-answer-${j}`}
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                    >
                      <Checkbox
                        key={`quiz-checkbox-${props.index}-question-${i}-answer-${j}`}
                        checked={answers && answers[i] === j}
                        disabled={props.thumbnail}
                        onChange={(e) => {
                          if (e.target.value) {
                            singleAnswerConfirmation();
                            const newAnswers = [...answers];
                            newAnswers[i] = j;
                            setAnswers(newAnswers);
                            if (props.edit) {
                              const newCorrectAnswers = selectedCorrectAnswers
                                ? [...selectedCorrectAnswers]
                                : [];
                              newCorrectAnswers[i] = j;
                              setCorrectAnswers(newCorrectAnswers);
                            }
                          }
                        }}
                        style={{ marginRight: theme.spacing(2) }}
                      />
                      <TextContent
                        key={`quiz-${props.index}-question-${i}-answer-${j}`}
                        content={a}
                        multiline
                        minimalEditor
                        height={theme.spacing(8)}
                        width={narrowScreen ? 200 : "auto"}
                        edit={props.edit}
                        onValueChanged={(val) => {
                          if (selectedAnswers && selectedAnswers[i]) {
                            const newAnswers = JSON.parse(
                              JSON.stringify(selectedAnswers)
                            );
                            newAnswers[i][j] = val;
                            setQuizAnswers(newAnswers);
                          }
                        }}
                      />
                      {props.edit && (
                        <Button
                          key={`quiz-button-${props.index}-question-${i}-answer-${j}`}
                          onClick={() => {
                            if (selectedAnswers) {
                              const newAnswers = JSON.parse(
                                JSON.stringify(selectedAnswers)
                              );
                              newAnswers[i].splice(j, 1);
                              setQuizAnswers(newAnswers);
                            }
                          }}
                        >
                          <DeleteOutline />
                        </Button>
                      )}
                    </Box>
                  );
                })}
              {props.edit &&
              (!selectedAnswers || selectedAnswers[i].length < 4) ? (
                <Box minWidth={300}>
                  <Button
                    variant="text"
                    onClick={() => {
                      if (selectedAnswers) {
                        const newAnswers = selectedAnswers
                          ? [...selectedAnswers]
                          : [[]];
                        newAnswers[i].push("Vastaus");
                        setQuizAnswers(newAnswers);
                      }
                    }}
                  >
                    {t("newAnswer")}
                  </Button>
                </Box>
              ) : (
                <Box height={theme.spacing(4)} />
              )}
            </Grid>
          </>
        ))}

      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        {props.edit && (
          <Button
            variant="text"
            onClick={() => {
              const newQuestions = selectedQuestions
                ? [...selectedQuestions]
                : [];
              newQuestions.push("Kysymys?");
              setQuizQuestions(newQuestions);

              const newQuizAnswers = selectedAnswers
                ? [...selectedAnswers]
                : [];
              newQuizAnswers.push(["Vastaus"]);
              setQuizAnswers(newQuizAnswers);

              const newAnswers = answers ? [...answers] : [];
              newAnswers.push(undefined);
              setAnswers(newAnswers);
            }}
          >
            {t("newQuestion")}
          </Button>
        )}
        {!props.edit && !checkSingleSelectionMode() && !props.thumbnail && (
          <Button
            disabled={props.thumbnail}
            variant="contained"
            onClick={() => checkAnswers()}
          >
            {t("checkAnswers")}
          </Button>
        )}
        {props.edit ? (
          <Box
            sx={{
              width: "100%",
              backgroundColor: colors.settingsBoxColor,
              borderRadius: 0.5,
              borderWidth: 1,
              borderStyle: "none",
              borderColor: colors.textHover,
              marginBottom: theme.spacing(1),
              padding: theme.spacing(1),
            }}
          >
            <Typography>{t("howManyQuestions")}</Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              sx={{ marginBottom: 1, input: { color: "black", width: 40 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ color: colors.textHover }}
                  >
                    /{quizQuestions?.length || 0}
                  </InputAdornment>
                ),
              }}
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                // Save the value only if the value is larger than 0 and smaller than maximum number of questions
                const newNum = parseInt(event.target.value);
                if (
                  quizQuestions?.length &&
                  newNum >= 0 &&
                  newNum <= quizQuestions?.length
                ) {
                  setNumberOfQuestions(newNum);
                  if (newNum < numberOfCorrectAnswers) {
                    setNumberOfCorrectAnswers(newNum);
                  }
                }
              }}
              value={numberOfQuestions}
              type="number"
              error={
                numberOfQuestions > (quizQuestions?.length || 0) ||
                numberOfQuestions < 0
              }
            />
            <Typography>{t("howManyCorrectAnswers")}</Typography>
            <TextField
              value={numberOfCorrectAnswers}
              id="outlined-basic"
              variant="outlined"
              sx={{ input: { color: "black", width: 40 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ color: colors.textHover }}
                  >
                    /{numberOfQuestions || 0}
                  </InputAdornment>
                ),
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                // Save the value only if the value is larger than 0 and smaller than number of shown questions

                if (
                  parseInt(event.target.value) >= 0 &&
                  parseInt(event.target.value) <= numberOfQuestions
                ) {
                  setNumberOfCorrectAnswers(parseInt(event.target.value));
                }
              }}
              size="small"
              type="number"
              error={
                numberOfCorrectAnswers > numberOfQuestions ||
                numberOfCorrectAnswers < 0
              }
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Grid>
  );
};
