import React, { useMemo, useState } from "react";
import {
  Modal,
  Card,
  Typography,
  Button,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { theme } from "../../../theme";
import colors from "../../../colors";
import { useTranslation } from "react-i18next";
import { IGalleryUrl } from "@backend/model/gallery";

interface IGalleryModalProps {
  galleryImages: IGalleryUrl[];
  ownImages: IGalleryUrl[];
  addGalleryImage: (image: IGalleryUrl) => void;
  addImageToGallery: () => void;
  isVisible: boolean;
  setVisible: (val: boolean) => void;
  addImage: () => void;
}

export const GalleryModal = (props: IGalleryModalProps) => {
  const { t } = useTranslation();
  const { ownImages, galleryImages } = props;
  // selected image
  const [selectedImage, setSelectedImage] = useState<IGalleryUrl | undefined>(
    undefined
  );

  const [filter, setFilter] = useState<"all" | "ownImages" | "gallery">("all");

  const filteredImages = useMemo(() => {
    if (filter === "all") return [...galleryImages, ...ownImages];
    else if (filter === "ownImages") return [...ownImages];
    else if (filter === "gallery") return [...galleryImages];
    else return [];
  }, [galleryImages, ownImages, filter]);

  return (
    <Modal
      open={props.isVisible}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          padding: theme.spacing(3),
          background: colors.backgroundSecondary,
        }}
      >
        <Typography variant="body1" sx={{ margin: 1 }}>
          {t("selectImage")}
        </Typography>

        {/* TABS */}

        <Button
          onClick={() => setFilter("all")}
          variant={filter === "all" ? "contained" : "outlined"}
          sx={{ margin: theme.spacing(0, 1) }}
        >
          {t("all")}
        </Button>
        <Button
          onClick={() => setFilter("gallery")}
          variant={filter === "gallery" ? "contained" : "outlined"}
          sx={{ margin: theme.spacing(0, 1) }}
        >
          {t("gallery")}
        </Button>
        <Button
          onClick={() => setFilter("ownImages")}
          variant={filter === "ownImages" ? "contained" : "outlined"}
          sx={{ margin: theme.spacing(0, 1) }}
        >
          {t("ownImages")}
        </Button>

        <Button
          variant={"text"}
          onClick={props.addImageToGallery} // change addimage to addImageToGallery
          sx={{ marginLeft: theme.spacing(4), justifySelf: "flex-end" }}
        >
          {t("uploadImage")}
        </Button>

        {/* GALLERY */}

        <ImageList key={"list" + filter} sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
          {filteredImages.map((item) => (
            <ImageListItem
              sx={
                selectedImage?.imageName === item.imageName
                  ? { overflow: "hidden", border: "5px solid #3290E2" }
                  : { overflow: "hidden" }
              }
              key={`key-${item.imageName}`}
            >
              <img
                srcSet={`${item}`}
                src={`${item.imageUrl}`}
                alt={item.imageName}
                loading="lazy"
                onClick={() => {
                  setSelectedImage(item);
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>

        <Button
          variant="text"
          style={{ paddingTop: theme.spacing(2) }}
          onClick={() => {
            props.setVisible(false);
            props.addGalleryImage(selectedImage || { imageName: "", imageUrl: "" });
          }}
        >
          {t("selectAnImage")}
        </Button>
        {/* filter === "ownImages" && (<Button
          variant="text"
          style={{ paddingTop: theme.spacing(2) }}
          onClick={() => {
            //props.setVisible(false);
            props.addImageToGallery();
          }}
        >
          {t("addToGallery")}
        </Button>)*/}
        <Button
          variant="text"
          style={{ paddingTop: theme.spacing(2) }}
          onClick={() => {
            props.setVisible(false);
          }}
        >
          {t("cancel")}
        </Button>
      </Card>
    </Modal>
  );
};
