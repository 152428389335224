import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useCompleteCourseMutation,
  useGetCourseByIdQuery,
} from "../apis/coursesAPI";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Link,
  MobileStepper,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { mobileWidth } from "../styleUtils";
import { Slide } from "./components/slide";
import { useNavigate, useSearchParams } from "react-router-dom";
import colors from "../colors";
import { ViewContainer } from "./components/viewContainer";
import { useTranslation } from "react-i18next";
import config from "../config";
import { theme } from "../theme";
import {
  useEditRoleMutation,
  useGetMissingFieldsQuery,
} from "../apis/rolesAPI";
import { MissingFieldsQuery } from "./components/missingFieldsQuery";
import { IRole } from "@backend/model/role";

const getSlideIndex = (index: number) => `slide-${index}`;

export const PlayCourse = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const userId = useMemo(
    () => searchParams.get("userId")?.replace(" ", "+"),
    [searchParams]
  );
  const [password, setPassword] = useState(
    userId === config.publicCourseUserId ? config.publicCoursePassword : ""
  );
  const [tempPassword, setTempPassword] = useState("");
  const [tosAccepted, setTosAccepted] = useState(false);
  const courseId = useMemo(() => searchParams.get("courseId"), [searchParams]);
  const apiKey = useMemo(() => searchParams.get("apiKey"), [searchParams]);
  const organisationId = useMemo(
    () => searchParams.get("organisationId"),
    [searchParams]
  );

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [slideReady, setSlideReady] = useState<{
    [slideIndex: string]: boolean;
  }>({});

  const [showPassModal, setShowPassModal] = useState(
    !!(userId && userId !== config.publicCourseUserId && apiKey)
  );

  const {
    data,
    isFetching: loadingCourse,
    error: dataError,
  } = useGetCourseByIdQuery(
    {
      organisationId: organisationId!,
      courseId: courseId!,
      userId: userId || "",
      apiKey: apiKey || "",
      password,
    },
    {
      skip: !organisationId || !courseId || !!(apiKey && !password),
    }
  );

  const {
    data: missingFields,
    isFetching: loadingMissingFields,
    error: missingFieldsError,
  } = useGetMissingFieldsQuery(
    {
      organisationId: organisationId!,
      courseId: courseId!,
      userId: userId || "",
      apiKey: apiKey || "",
      password,
    },
    {
      skip: !organisationId || !courseId || !apiKey || !password,
    }
  );

  const [completeCourse] = useCompleteCourseMutation();
  const [editRole] = useEditRoleMutation();

  const selectedSlide = useMemo(
    () =>
      data && data.slides.length >= selectedIndex
        ? data.slides[selectedIndex]
        : undefined,
    [selectedIndex, data]
  );

  useEffect(() => {
    if (
      data &&
      selectedIndex >= data.slides.length - 1 &&
      !completed &&
      slideReady[getSlideIndex(selectedIndex)]
    ) {
      console.log("set completed");
      setCompleted(true);
    }
  }, [selectedIndex, data, completed, slideReady]);

  useEffect(() => {
    const doComplete = async () => {
      if (
        organisationId &&
        userId &&
        data &&
        apiKey &&
        userId !== config.publicCourseEmail
      ) {
        await completeCourse({
          organisationId,
          userId,
          courseId: data.id,
          apiKey,
          password,
        }).unwrap();
      }
    };

    if (completed) doComplete();
    if (data && showPassModal) {
      setShowPassModal(false);
    }
  }, [
    completed,
    data,
    organisationId,
    completeCourse,
    userId,
    apiKey,
    password,
    showPassModal,
  ]);

  const onSlideReady = useCallback(() => {
    console.log(`slide ${selectedIndex} is now ready`);
    if (!slideReady[getSlideIndex(selectedIndex)]) {
      setSlideReady((val) =>
        Object.assign({}, val, { [getSlideIndex(selectedIndex)]: true })
      );
    }
  }, [selectedIndex, slideReady]);

  const narrowScreen = useMediaQuery(mobileWidth);

  console.log(dataError);

  return (
    <ViewContainer noPadding={narrowScreen} loading={loadingCourse}>
      <Box
        flex={1}
        display={"flex"}
        height={"100%"}
        flexDirection={"column"}
        position={"relative"}
      >
        {selectedSlide && (
          <Box sx={{ height: narrowScreen ? `calc(100% - 60px)`: "100%" }}>
            <Slide
              key={`slide-${selectedIndex}`}
              slide={selectedSlide}
              organisationId={organisationId || ""}
              slideUpdated={() => {}}
              edit={false}
              setReady={onSlideReady}
              marginBottom={theme.spacing(5)}
              password={password}
            />
          </Box>
        )}
        <MobileStepper
          sx={{
            //TODO: check if relative position works better.
            position: apiKey ? "fixed" : narrowScreen ? "relative" : "absolute",
            bottom: narrowScreen ? theme.spacing(1) : 0,
            zIndex: 2,
            paddingBottom: 0,
            marginBottom: -1,
            height: 60,
          }}
          position="bottom"
          backButton={
            <Button
              variant="contained"
              onClick={() => setSelectedIndex(selectedIndex - 1)}
              disabled={!(selectedIndex > 0)}
            >
              {t("previousSlide")}
            </Button>
          }
          nextButton={
            data && selectedIndex < data.slides.length - 1 ? (
              <Button
                variant="contained"
                disabled={!slideReady[getSlideIndex(selectedIndex)]}
                onClick={() => setSelectedIndex(selectedIndex + 1)}
              >
                {t("nextSlide")}
              </Button>
            ) : (
              <Box width={112} />
            )
          }
          steps={data?.slides.length || 1}
          activeStep={selectedIndex}
        />
        <Modal
          open={showPassModal || !!(missingFields && missingFields.length)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Card
            style={{
              background: colors.backgroundSecondary,
              maxHeight: "90%",
              overflow: "auto",
            }}
          >
            {courseId && showPassModal && (
              <Box
                maxWidth={500}
                padding={theme.spacing(2)}
                alignItems={"center"}
                display={"flex"}
                flexDirection={"column"}
              >
                <Typography
                  variant="h4"
                  style={{
                    marginBottom: theme.spacing(2),
                    textAlign: "center",
                  }}
                >
                  {t("welcomeToCourse")}
                </Typography>
                <Typography variant="body1" textAlign={"center"}>
                  {t("tosDisclaimer")}
                </Typography>
                <Box flexDirection="column" margin={theme.spacing(2)}>
                  <Checkbox
                    checked={tosAccepted}
                    onChange={(e) => {
                      setTosAccepted(e.target.checked);
                    }}
                  />
                  <Typography variant="caption">
                    {`${t("readedTos")} `}
                    <Link
                      href="https://www.inguider.fi/termsofservice"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("tos")}
                    </Link>
                  </Typography>
                </Box>
                {dataError && (dataError as any).originalStatus === 401 && (
                  <Typography variant="caption" color={"red"}>
                    {t("checkPassword")}
                  </Typography>
                )}
                <TextField
                  label={t("password")}
                  value={tempPassword}
                  style={{ margin: theme.spacing(1) }}
                  onChange={(e) =>
                    setTempPassword(e.target.value.toUpperCase())
                  }
                />

                {loadingCourse && <CircularProgress />}
                {!loadingCourse && (
                  <Button
                    variant="contained"
                    disabled={!tempPassword || !tosAccepted}
                    onClick={() => {
                      setPassword(tempPassword);
                    }}
                  >
                    {t("confirm")}
                  </Button>
                )}
              </Box>
            )}
            {missingFields && missingFields.length && (
              <MissingFieldsQuery
                missingFields={missingFields}
                onSave={(personData: IRole) => {
                  editRole({
                    ...personData,
                    id: userId || "",
                    organisationId: organisationId || "",
                    courseId: courseId || "",
                    apiKey: apiKey || "",
                    password,
                  });
                }}
              />
            )}
          </Card>
        </Modal>
      </Box>
    </ViewContainer>
  );
};
