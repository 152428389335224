import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Box, Modal, Card, Typography, Button } from "@mui/material";
import { ISlideProps } from "../slide";
import { theme } from "../../../theme";
import colors from "../../../colors";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowsProp,
} from "@mui/x-data-grid";
import { ICourse } from "@backend/model/course";

interface IInviteModalProps {
  users: GridRowId[]; // GridRowId for users gets a list of ids.
  courses?: ICourse[];
  sendInvite: (users: GridRowId[], courses: GridRowId[]) => void;
  isVisible: boolean;
  setVisible: (val: boolean) => void;
}

export const InviteModal = (props: IInviteModalProps) => {
  const { t } = useTranslation();
  // selected courses
  const [selectedCourses, setSelectedCourses] = useState<GridRowId[]>([]);

  const data: GridRowsProp =
    props.courses?.map((c) => {
      return {
        ...c,
      };
    }) || [];
  const columns: GridColDef[] = useMemo(() => {
    let result = [
      //{ field: "id", headerName: t("id"), width: 120 },
      { field: "name", headerName: t("name"), width: 120 },
      { field: "description", headerName: t("description"), width: 240 },
    ];
    return result;
  }, [t]);

  return (
    <Modal
      open={props.isVisible}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          padding: theme.spacing(3),
          background: colors.backgroundSecondary,
        }}
      >
        <Typography>{t("selectCourses")}</Typography>
        <DataGrid
          sx={{
            width: "calc(50vw)",
            borderStyle: "none",
            height: "75%",
            ".MuiDataGrid-cell": {
              borderColor: "#555",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          checkboxSelection
          rows={data}
          columns={columns}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            // Selecting a checkbox saves the email of every selected row on the list to rowSelectionModel-state
            setSelectedCourses(newRowSelectionModel);
          }}
        />

        <Button
          variant="text"
          style={{ paddingTop: theme.spacing(2) }}
          onClick={() => {
            props.sendInvite(props.users, selectedCourses); // users, courses
            props.setVisible(false);
          }}
        >
          {t("send")}
        </Button>
        <Button
          variant="text"
          style={{ paddingTop: theme.spacing(2) }}
          onClick={() => {
            props.setVisible(false);
          }}
        >
          {t("cancel")}
        </Button>
      </Card>
    </Modal>
  );
};
