import { Button } from "@mui/material";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";


var imageAutoOpenedTimestamp = new Date();

interface IImageUploadContentProps {
    onImageUpload: () => void;
    onImageUpdate: (index: number) => void;
    onFillModeChanged?: (mode: string) => void;
    imageList: any[];
    metadata: any;
    content: string;
    autoOpen?: boolean;
    showUploadBtn?: boolean;
    forcedFillMode?: string;
  }
  
  export const ImageUploadContent = ({
    onImageUpload,
    onImageUpdate,
    onFillModeChanged,
    imageList,
    metadata,
    content,
    autoOpen,
    showUploadBtn,
    forcedFillMode,
  }: IImageUploadContentProps) => {
    const [autoOpenImage, setAutoOpenImage] = useState(autoOpen);
    const upload = { onUpdate: onImageUpdate, onUpload: onImageUpload };
    const uploadRef = useRef(upload);
    useEffect(() => {
      uploadRef.current = {
        onUpdate: onImageUpdate,
        onUpload: onImageUpload,
      };
    }, [onImageUpdate, onImageUpload]);
  
    useEffect(() => {
      if (autoOpen) {
        setAutoOpenImage(autoOpen);
      }
    }, [autoOpen]);
  
    const hasImage = useRef(imageList.length > 0);
    useEffect(() => {
      hasImage.current = imageList.length > 0;
    }, [imageList.length]);
  
    useEffect(() => {
      if (
        autoOpenImage &&
        new Date().getTime() - imageAutoOpenedTimestamp.getTime() > 500
      ) {
        if (hasImage.current) uploadRef.current.onUpdate(0);
        else uploadRef.current.onUpload();
        imageAutoOpenedTimestamp = new Date();
      }
      setAutoOpenImage(false);
    }, [autoOpenImage]);
  
    return (
      <>
        {imageList.map((image: any) => (
          <img
            src={image["data_url"]}
            alt=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: metadata.fillMode ? metadata.fillMode : "contain",
            }}
          />
        ))}
        {onImageUpload && showUploadBtn && (
          <Button
            style={{
              width: 150,
              height: 40,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(${-75}px, ${-20}px)`,
            }}
            variant="contained"
            onClick={() => {
              if (imageList.length > 0) onImageUpdate(0);
              else onImageUpload();
            }}
          >
            {content && content.length ? t("changeImage") : t("addImage")}
          </Button>
        )}
      </>
    );
  };