import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { ISlideProps } from "../slide";
import { theme } from "../../../theme";
import { TextContent } from "../slideTextContent";
import { VideoContent } from "../slideVideoContent";
import { OndemandVideo } from "@mui/icons-material";
import { SlideContentSelector } from "../SlideContentSelector";
import colors from "../../../colors";

export const OneElenentSlide = (props: ISlideProps) => {
  const { setReady } = props;

  const [contentsReady, setContentsReady] = useState([false]);

  const setReadyCallback = useCallback((index: number) => {
    setContentsReady((current) => {
      const newContentsReady = [...current];
      newContentsReady[index] = true;
      return newContentsReady;
    });
  }, []);

  useEffect(() => {
    let result = true;
    for (let i = 0; i < contentsReady.length; i++) {
      if (!contentsReady[i]) result = false;
    }
    if (result) setReady();
  }, [setReady, contentsReady]);

  return (
    <Box
      flex={1}
      flexGrow={1}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      justifyItems={"center"}
    >
      <SlideContentSelector
        content={props.content && props.content[0]}
        setContent={props.setContent}
        edit={props.edit}
        index={0}
        thumbnail={props.thumbnailSlide}
        onSetReady={setReadyCallback}
        organisationId={props.organisationId}
        password={props.password}
      />
    </Box>
  );
};
