import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Button } from "@mui/material";
import { theme } from "../../theme";
import { ImageRounded } from "@mui/icons-material";
import { t } from "i18next";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { getBase64 } from "../../utils/common";
import { ImageUploadContent } from "./ImageUploadContent";


interface IGalleryContentProps {
  content: string;
  edit?: boolean;
  onValueChanged?: (val: string) => void;
  onFillModeChanged?: (fillMode: string) => void;
  metadataStr?: string;
  onSetReady?: () => void;
  autoOpen?: boolean;
  showUploadBtn?: boolean;
  forcedFillMode?: string;
}

export const GalleryContent = (props: IGalleryContentProps) => {
  const [images, setImages] = React.useState([]);
  const [enableImage, setEnableImage] = React.useState(true); 

  const onChange = async (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {

    // data for submit
    setImages(imageList as never[]);

    if (imageList[0].file && props.onValueChanged) {
      const base64 = await getBase64(imageList[0].file);
      props.onValueChanged(base64);
    }
  };

  const { onSetReady, showUploadBtn, autoOpen, content, forcedFillMode, onFillModeChanged } =
    props;
  useEffect(() => onSetReady && onSetReady(), [onSetReady]);

  const metadata = useMemo(
    () => (props.metadataStr ? JSON.parse(props.metadataStr) : {}),
    [props.metadataStr]
  );

  const imageUploadCallback = useCallback(
    ({
      imageList,
      onImageUpload,
      onImageRemoveAll,
      onImageUpdate,
      onImageRemove,
      forcedFillMode,
    }: any) => (
      <ImageUploadContent
        onImageUpdate={onImageUpdate}
        onImageUpload={onImageUpload}
        onFillModeChanged={onFillModeChanged}
        content={content}
        imageList={imageList}
        metadata={metadata}
        autoOpen={autoOpen}
        showUploadBtn={showUploadBtn}
      />
    ),
    [metadata, autoOpen, content, showUploadBtn, forcedFillMode, onFillModeChanged]
  );

  return (
    <Box width={"100%"} height={"100%"} position={"relative"}>
      {props.content && props.content.length && enableImage ? (
        <img
          src={content}
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            setEnableImage(false); // hide image on error
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderStyle: "none",
            borderWidth: 1,
            borderRadius:2,
            borderColor: "#fff7", // white with 50% opacity
            objectFit: forcedFillMode
              ? forcedFillMode
              : metadata.fillMode
              ? metadata.fillMode
              : "contain",
          }}
        />
      ) : (
        <ImageRounded
          sx={{
            alignSelf: "center",
            color: theme.palette.primary.light,
            opacity: "50%",
            width: "80%",
            height: "80%",
            position: "absolute",
            top: "10%",
            left: "10%",
          }}
        />
      )}
      {props.edit && (
        <ImageUploading
          value={images}
          onChange={onChange}
          maxNumber={1}
          dataURLKey="data_url"
        >
          {imageUploadCallback}
        </ImageUploading>
      )}
    </Box>
  );
};