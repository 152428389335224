import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useCreateCourseMutation,
  useGetCourseByIdQuery,
  useListImagesQuery,
  useListMessagesQuery,
  useListOrganisationImagesQuery,
  useUpdateCourseMutation,
} from "../apis/coursesAPI";
import { selector } from "../store";
import { getOrganisation } from "../slices/organisationSlice";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  MobileStepper,
  Modal,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import type { ISlideDto } from "@backend/dto/slideDto";
import { Slide } from "./components/slide";
import { useLocation, useNavigate } from "react-router-dom";
import { ISlideContent, ISlideType } from "@backend/model/slide";
import { ImageContent } from "./components/slideImageContent";
import { ICourseRecurrency } from "@backend/model/course";
import { ViewContainer } from "./components/viewContainer";
import { ReactComponent as OneElementIcon } from "../assets/icons/1element.svg";
import { ReactComponent as OneElementWithTitleIcon } from "../assets/icons/1element_title.svg";
import { ReactComponent as TwoColumnsWithTitleIcon } from "../assets/icons/2elements_title.svg";
import { ReactComponent as TwoColumnsIcon } from "../assets/icons/2elements.svg";
import { ReactComponent as GridWithTitleIcon } from "../assets/icons/3elements_title.svg";
import { ReactComponent as GridIcon } from "../assets/icons/3elements.svg";

import { useTranslation } from "react-i18next";
import { GalleryModal } from "./components/modals/galleryModal";
import { GalleryContent } from "./components/slideGalleryContent";

export const CreateCourse = () => {
  const org = selector((state) => getOrganisation(state));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [slides, setSlides] = useState<ISlideDto[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [image, setImage] = useState("");
  const [refreshSlide, setRefreshSlide] = useState(0);
  const [menuIndex, setMenuIndex] = useState<number | undefined>(undefined);
  const [anchorElement, setAnchorElement] = useState();
  const [bgIndex, setBgIndex] = useState<number | undefined>(1);
  const [showGalleryModal, setShowGalleryModal] = useState(false);

  const setContent = (index: number, data: string, type: string) => {
    // determine, which content block is used for background image
    let bgContentIndex = 1;
    if (slides[index].type == "one-element") bgContentIndex = 1;
    if (slides[index].type == "one-element-with-title") bgContentIndex = 2;
    if (slides[index].type == "two-columns") bgContentIndex = 2;
    if (slides[index].type == "two-columns-with-title") bgContentIndex = 3;
    if (slides[index].type == "grid") bgContentIndex = 3;
    if (slides[index].type == "grid-with-title") bgContentIndex = 4;
    // deep copy slides, and save the updated content.
    let slidesCopy = JSON.parse(JSON.stringify(slides));
    const newContent = {
      data: data,
      contentType: type,
      metadata: { fillMode: "cover" },
    };
    slidesCopy[index].content[bgContentIndex] = newContent;
    setSlides(Object.assign(slides, slidesCopy));
    // Refresh view
    setRefreshSlide(refreshSlide + 1);
  };

  const { data: messages, isFetching: messagesLoading } = useListMessagesQuery(
    {
      organisationId: org?.organisationId || "",
      courseId: state?.courseId,
    },
    {
      skip: !org || !state?.courseId,
    }
  );

  const { data, isFetching: loadingCourse } = useGetCourseByIdQuery(
    {
      organisationId: org?.organisationId || "",
      courseId: state?.courseId,
    },
    {
      skip: !org || !state?.courseId,
    }
  );

  const {
    data: galleryImages,
    isFetching: imagesFetching,
    isLoading: imagesLoading,
    refetch: refetchGalleryImages,
  } = useListImagesQuery(org!.organisationId);

  const {
    data: organisationGalleryImages,
    isFetching: organisationGalleryImagesFetching,
    isLoading: organisationGalleryImagesLoading,
  } = useListOrganisationImagesQuery(org!.organisationId);

  const [galleryAutoOpenImage, setGalleryAutoOpenImage] = useState(false);
  useEffect(() => {
    if (galleryAutoOpenImage) {
      setGalleryAutoOpenImage(false);
    }
  }, [galleryAutoOpenImage]);

  const [autoOpenImage, setAutoOpenImage] = useState(false);
  useEffect(() => {
    if (autoOpenImage) setAutoOpenImage(false);
  }, [autoOpenImage]);

  const recordAnchorElement = (event: any) => {
    setAnchorElement(event.currentTarget);
  };
  const [deleteSlide, setDeleteSlide] = useState<ISlideDto | undefined>(
    undefined
  );

  const switchSlide = (sourceIndex: number, targetIndex: number) => {
    // Deep copy slides-array
    var editSlides = JSON.parse(JSON.stringify(slides));
    // switch array elements
    var tempSlide = editSlides[targetIndex];
    editSlides[targetIndex] = editSlides[sourceIndex];
    editSlides[sourceIndex] = tempSlide;
    // swap indices
    editSlides[sourceIndex].index = sourceIndex;
    editSlides[targetIndex].index = targetIndex;
    // save slides and refresh
    setSlides(editSlides);
    setRefreshSlide(refreshSlide + 1);
  };

  useEffect(() => {
    if (data) {
      setName(data.name);
      setDescription(data.description);
      setSlides(data.slides);
      setImage(data.image);
      setRecurrency(data.recurrency || "");
    }
  }, [data]);

  const [slideSelectionShown, setSlideSelectionShown] = useState(false);

  const [createCourse, { isLoading: createCourseLoading }] =
    useCreateCourseMutation();
  const [updateCourse, { isLoading: updateCourseLoading }] =
    useUpdateCourseMutation();

  const [mode, setMode] = useState<"editSlides" | "editInfo" | "messages">(
    "editInfo"
  );
  const [recurrency, setRecurrency] = useState<ICourseRecurrency>("");

  const selectedSlide = useMemo(
    () => (slides.length >= selectedIndex ? slides[selectedIndex] : undefined),
    [selectedIndex, slides, refreshSlide]
  );

  const theme = useTheme();

  const slideUpdated = useCallback(
    (s: ISlideDto) => {
      setSlides((current) => {
        const newSlides = [...current];
        newSlides[selectedIndex] = s;
        return newSlides;
      });
    },
    [selectedIndex]
  );

  const handleRecurrencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRecurrency(
      (event.target as HTMLInputElement).value as ICourseRecurrency
    );
  };

  const onSaveCallback = useCallback(async () => {
    if (org && !data) {
      await createCourse({
        course: { name, description, slides, image, recurrency },
        organisationId: org.organisationId,
      }).unwrap();
    } else if (org && data) {
      await updateCourse({
        course: { name, description, slides, image, recurrency },
        organisationId: org.organisationId,
        courseId: data.id,
      }).unwrap();
    }
    navigate(-1);
  }, [
    createCourse,
    updateCourse,
    org,
    name,
    description,
    slides,
    data,
    navigate,
    image,
    recurrency,
  ]);

  const onDeleteSlide = (slide: ISlideDto, index: number) => {
    const filteredSlides = slides.filter((s) => s.index !== slide.index);

    // Recalculate indices for the remaining slides and save them.
    const reIndexedSlides = filteredSlides.map((element, index) => {
      return { ...element, index: index };
    });
    setSlides(reIndexedSlides);

    if (selectedIndex >= index && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
    setRefreshSlide(refreshSlide + 1);
  };

  const onAddSlide = (slideType: ISlideType) => {
    let content: Array<ISlideContent> = [];
    if (slideType === "one-element")
      content = [{ contentType: "unknown", data: "" }];
    else if (slideType === "one-element-with-title")
      content = [
        {
          contentType: "text",
          data: '<h1 class="ql-align-center"><span class="ql-size-large">Title</span></h1>',
        },
        { contentType: "unknown", data: "" },
      ];
    else if (slideType === "two-columns-with-title")
      content = [
        {
          contentType: "text",
          data: '<h1 class="ql-align-center"><span class="ql-size-large">Title</span></h1>',
        },
        { contentType: "unknown", data: "" },
        { contentType: "unknown", data: "" },
      ];
    else if (slideType === "two-columns")
      content = [
        { contentType: "unknown", data: "" },
        { contentType: "unknown", data: "" },
      ];
    else if (slideType === "grid-with-title")
      content = [
        {
          contentType: "text",
          data: '<h1 class="ql-align-center"><span class="ql-size-large">Title</span></h1>',
        },
        { contentType: "unknown", data: "" },
        { contentType: "unknown", data: "" },
        { contentType: "unknown", data: "" },
      ];
    else if (slideType === "grid")
      content = [
        { contentType: "unknown", data: "" },
        { contentType: "unknown", data: "" },
        { contentType: "unknown", data: "" },
      ];

    setSelectedIndex(slides.length);
    setSlides(
      slides.concat({
        type: slideType,
        content,
        index: slides.length,
      })
    );
    setSlideSelectionShown(false);
  };

  return (
    <ViewContainer
      loading={
        loadingCourse ||
        createCourseLoading ||
        updateCourseLoading ||
        messagesLoading
      }
    >
      <Box
        flex={1}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        position={"relative"}
        sx={{ paddingBottom: theme.spacing(4) }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={mode === "editInfo" ? 0 : mode === "editSlides" ? 1 : 2}
            onChange={(e: any) => setMode(e.target.id)}
          >
            <Tab label={t("info")} id="editInfo" />
            <Tab label={t("slides")} id="editSlides" />
            <Tab label={t("messages")} id="messages" />
          </Tabs>
        </Box>
        <Box
          display={"flex"}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          {mode === "editSlides" && (
            <>
              <Button
                variant="text"
                onClick={() => {
                  setSlideSelectionShown(true);
                }}
                sx={{ marginRight: theme.spacing(4) }}
              >
                {t("addSlide")}
              </Button>
            </>
          )}
          <Button variant="contained" onClick={onSaveCallback}>
            {t("save")}
          </Button>
        </Box>
        {mode === "editInfo" && (
          <>
            <TextField
              variant="outlined"
              label={t("name")}
              margin="normal"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <TextField
              variant="outlined"
              label={t("description")}
              margin="normal"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
            <Divider
              variant="fullWidth"
              orientation="horizontal"
              flexItem
              style={{ margin: 8 }}
            />
            <Typography
              style={{ color: theme.palette.text.secondary }}
              variant="body1"
            >
              {t("backgroundImage")}
            </Typography>
            <Box
              width={300}
              height={300}
              marginTop={theme.spacing(1)}
              marginBottom={theme.spacing(1)}
              bgcolor={"background.paper"}
            >
              <ImageContent
                content={image}
                onValueChanged={(e) => setImage(e)}
                edit
                showUploadBtn
              />
            </Box>
            <Divider
              variant="fullWidth"
              orientation="horizontal"
              flexItem
              style={{ margin: 8 }}
            />
            <Typography
              style={{ color: theme.palette.text.secondary }}
              variant="body1"
            >
              {t("recurrency")}
            </Typography>
            <RadioGroup
              defaultValue=""
              name="recurrency"
              value={recurrency}
              onChange={handleRecurrencyChange}
            >
              <FormControlLabel
                value={""}
                control={<Radio />}
                label={t("none")}
              />
              <FormControlLabel
                value={"month"}
                control={<Radio />}
                label={t("monthly")}
              />
              <FormControlLabel
                value={"year"}
                control={<Radio />}
                label={t("yearly")}
              />
            </RadioGroup>
          </>
        )}
        {mode === "editSlides" && (
          <Box
            key={`organizeSideBar-${refreshSlide}`}
            display={"flex"}
            sx={{ flex: 1, flexDirection: "column" }}
          >
            {/* Slide organization on the side bar */}
            {selectedSlide && (
              <Box display={"flex"} sx={{ flex: 1, flexDirection: "row" }}>
                <Box
                  key={`organizeSideBar2-${refreshSlide}`}
                  style={{
                    width: 190,
                    // Height 0 and minHeight 100% sets sidebar max height to same as slide height.
                    height: 0,
                    minHeight: "100%",
                    paddingBottom: theme.spacing(4),
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                      alignContent: "center",
                      marginLeft: theme.spacing(1),
                    }}
                  >
                    {slides.map((value: ISlideDto, index: number) => {
                      return (
                        <Box key={`slideIcon-${refreshSlide}-${index}`}>
                          <Typography
                            sx={{ marginRight: 1, alignSelf: "start" }}
                          >
                            {index + 1}
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setSelectedIndex(index);
                            }}
                            fullWidth
                            onContextMenu={(e) => {
                              e.preventDefault(); // Prevent the default right click menu.
                              recordAnchorElement(e); // Set anchor element to clicked button.
                              setMenuIndex(index); // Set menu index, so that only the correct menu is open.
                            }}
                            size="small"
                            sx={{
                              minHeight: 0,
                              minWidth: 0,
                              width: 160,
                              height: 100,
                              overflow: "hidden",
                              marginBottom: theme.spacing(1),
                              backgroundColor: "#fff",
                              "&:hover": {
                                backgroundColor: "#ccc",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                zoom: 0.205,
                                transformOrigin: "top center",
                                width: 200 * 4,
                                height: 120 * 4,
                                aspectRatio: 200/120 
                              }}
                            >
                              <Slide
                                key={`slide-${selectedIndex}-${refreshSlide}`}
                                slide={value}
                                slideUpdated={() => {}}
                                setReady={() => {}}
                                edit={false}
                                thumbnailSlide
                                organisationId={org?.organisationId || ""}
                              />
                            </Box>
                          </Button>
                          {
                            // Menu for moving slides up / down
                            <Menu
                              open={menuIndex === index}
                              onClose={() => setMenuIndex(undefined)}
                              anchorEl={anchorElement}
                            >
                              <MenuItem
                                onClick={() => {
                                  setDeleteSlide(value);
                                  setMenuIndex(undefined);
                                }}
                              >
                                {t("deleteSlide")}
                              </MenuItem>
                              {index > 0 && (
                                <MenuItem
                                  onClick={() => {
                                    switchSlide(index, index - 1);
                                    setMenuIndex(undefined);
                                  }}
                                >
                                  {t("moveUp")}
                                </MenuItem>
                              )}
                              {index < slides.length - 1 && (
                                <MenuItem
                                  onClick={() => {
                                    switchSlide(index, index + 1);
                                    setMenuIndex(undefined);
                                  }}
                                >
                                  {t("moveDown")}
                                </MenuItem>
                              )}
                              {/* Add backgroundImage */}
                              {
                                <MenuItem
                                  onClick={() => {
                                    setBgIndex(index);
                                    setShowGalleryModal(true);
                                    setMenuIndex(undefined);
                                  }}
                                >
                                  {t("addBackgroundImage")}
                                </MenuItem>
                              }
                              {/* Remove backgroundImage */}
                              {
                                <MenuItem
                                  onClick={() => {
                                    setBgIndex(index);
                                    if (index !== undefined)
                                      setContent(index, "", "image");
                                    setMenuIndex(undefined);
                                  }}
                                >
                                  {t("deleteBackgroundImage")}
                                </MenuItem>
                              }
                            </Menu>
                          }
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Slide
                  key={`slide-${selectedIndex}-${refreshSlide}`}
                  slide={selectedSlide}
                  slideUpdated={slideUpdated}
                  edit
                  setReady={() => {}}
                  organisationId={org?.organisationId || ""}
                />
              </Box>
            )}
            <MobileStepper
              sx={{
                position: "relative",
                bottom: 0,
                zIndex: 2,
                paddingBottom: 0,
              }}
              position={"bottom"}
              steps={slides.length}
              activeStep={selectedIndex}
              backButton={
                <Button
                  variant="contained"
                  disabled={!(selectedIndex > 0)}
                  onClick={() => setSelectedIndex(selectedIndex - 1)}
                >
                  {t("previousSlide")}
                </Button>
              }
              nextButton={
                <Button
                  variant="contained"
                  disabled={!(selectedIndex < slides.length - 1)}
                  onClick={() => setSelectedIndex(selectedIndex + 1)}
                >
                  {t("nextSlide")}
                </Button>
              }
            />
          </Box>
        )}
        {mode === "messages" && (
          <Box>
            <Typography variant="h4" sx={{ marginTop: theme.spacing(2) }}>
              {t("messages")}
            </Typography>
            <Divider
              sx={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
            />
            {messages?.map((m) => {
              return (
                <>
                  <Typography>{m.message}</Typography>
                  <Typography style={{ color: theme.palette.grey[500] }}>
                    {m.sender ? m.sender : " "}
                  </Typography>
                  <Divider
                    sx={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                </>
              );
            })}
          </Box>
        )}
        <ThemeProvider theme={theme}>
          <Modal
            open={slideSelectionShown}
            onClose={() => setSlideSelectionShown(false)}
          >
            <Card
              style={{
                width: "60vw",
                height: "auto",
                position: "absolute",
                top: "20vh",
                left: "20vw",
                paddingBottom: "5vh",
              }}
            >
              <Typography
                variant="h4"
                align={"center"}
                sx={{ padding: theme.spacing(2, 0) }}
              >
                {t("chooseLayout")}
              </Typography>
              <Grid
                container
                style={{
                  justifyContent: "center",
                }}
              >
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => onAddSlide("one-element")}
                    sx={{
                      flexDirection: "column",
                      color: "white",
                      textTransform: "inherit",
                    }}
                  >
                    <OneElementIcon />
                    {t("oneElementLayout")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => onAddSlide("two-columns")}
                    sx={{
                      flexDirection: "column",
                      color: "white",
                      textTransform: "inherit",
                    }}
                  >
                    <TwoColumnsIcon />
                    {t("twoColumnsLayout")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => onAddSlide("grid")}
                    sx={{
                      flexDirection: "column",
                      color: "white",
                      textTransform: "inherit",
                    }}
                  >
                    <GridIcon />
                    {t("threeElementsLayout")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => onAddSlide("one-element-with-title")}
                    sx={{
                      flexDirection: "column",
                      color: "white",
                      textTransform: "inherit",
                    }}
                  >
                    <OneElementWithTitleIcon />
                    {t("oneElementWithTitleLayout")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => onAddSlide("two-columns-with-title")}
                    sx={{
                      flexDirection: "column",
                      color: "white",
                      textTransform: "inherit",
                    }}
                  >
                    <TwoColumnsWithTitleIcon />
                    {t("twoColumnsWithTitleLayout")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => onAddSlide("grid-with-title")}
                    sx={{
                      flexDirection: "column",
                      color: "white",
                      textTransform: "inherit",
                    }}
                  >
                    <GridWithTitleIcon />
                    {t("threeElementsWithTitleLayout")}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Modal>
          {/* Confirmation modal for deleting slide */}
          <Modal
            open={!!deleteSlide}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card
              style={{
                padding: theme.spacing(3),
              }}
            >
              <Typography>{t("deleteSlideConfirmation")}</Typography>
              <Button
                variant="text"
                style={{ paddingTop: theme.spacing(2) }}
                onClick={() => {
                  if (!!deleteSlide) {
                    onDeleteSlide(deleteSlide, deleteSlide.index);
                    setDeleteSlide(undefined);
                  }
                }}
              >
                {t("ok")}
              </Button>
              <Button
                variant="text"
                style={{ paddingTop: theme.spacing(2) }}
                onClick={() => {
                  setDeleteSlide(undefined);
                }}
              >
                {t("cancel")}
              </Button>
            </Card>
          </Modal>
        </ThemeProvider>
      </Box>

      {/* Image content & gallery content are here to enable uploading images */}
      <Box sx={{ height: 0 }}>
        {
          <ImageContent
            content={""}
            edit={true}
            onValueChanged={(val) => {
              if (bgIndex !== undefined) setContent(bgIndex, val, "image");
            }}
            onFillModeChanged={() => {}}
            metadataStr={""}
            onSetReady={() => {}}
            autoOpen={autoOpenImage}
          />
        }
        {
          <GalleryContent
            content={""}
            edit={true}
            onValueChanged={(val) => {
              if (bgIndex !== undefined)
                setContent(bgIndex, val, "galleryImage");
            }}
            onFillModeChanged={() => {}}
            metadataStr={""}
            onSetReady={() => {}}
            autoOpen={galleryAutoOpenImage}
          />
        }
      </Box>

      {/* Gallery Modal for selecting the background images */}

      <GalleryModal
        galleryImages={galleryImages || []}
        ownImages={organisationGalleryImages || []}
        addGalleryImage={async (image) => {
          console.log("Selected");
          if (bgIndex !== undefined)
            setContent(bgIndex, image.imageUrl, "galleryImage");
        }}
        isVisible={showGalleryModal}
        setVisible={(val) => setShowGalleryModal(val)}
        addImage={() => {
          if (bgIndex !== undefined) setContent(bgIndex, "", "image");
          setAutoOpenImage(true);
          setShowGalleryModal(false);
        }}
        addImageToGallery={() => {
          if (bgIndex !== undefined) setContent(bgIndex, "", "galleryImage");
          setGalleryAutoOpenImage(true);
          setShowGalleryModal(false);
        }}
      />
    </ViewContainer>
  );
};
