// Style utils

import { Theme } from "@mui/material";

export const numberOfLines = (lines: number) => {
  return {
    display: "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };
};

// 768 seems to be a common breakpoint for mobile devices
// 768-1023 for tablets
// > 1024 for desktop

export const mobileWidth = "(max-width:768px)"

// Height for slides.
export const slideViewHeight = (thumbnail: boolean | undefined, narrowScreen: boolean, theme: Theme, title: boolean) => {
  if(thumbnail){
    return "100%"
  }
  else if (title) {
    return `calc(${narrowScreen ? "50" : "100"}% - ${theme.spacing(8)})`
  }
  else return narrowScreen ? "50" : "100"
}