import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyGetLinkQuery } from "../apis/linksApi";

export const OpenShort = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [fetchLink] = useLazyGetLinkQuery();
  const navigating = useRef(false);
  const searchParams = new URLSearchParams(location.search);
  const s = searchParams.get("s");
  console.log(location);

  useEffect(() => {
    const doNavigate = async (shortLink: string) => {
      navigating.current = true;
      const { url } = await fetchLink({ shortLink }).unwrap();
      console.log(url);
      const u = new URL(url);
      navigate(u.pathname + u.search);
    };
    if (s && !navigating.current) {
      doNavigate(s);
      console.log("try to navigate to ", s);
    }
  }, [s, navigate, fetchLink]);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};
