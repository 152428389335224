// Need to use the React-specific entry point to allow generating React hooks
import { baseAPI } from "./baseAPI";

const enchancedAPI = baseAPI.enhanceEndpoints({
  addTagTypes: ["links"],
});

// Define a service using a base URL and expected endpoints
export const linksApi = enchancedAPI.injectEndpoints({
  endpoints: (builder) => ({
    getLink: builder.query<
      { url: string },
      {
        shortLink: string;
      }
    >({
      query: ({ shortLink }) => ({
        url: `links/${shortLink}`,
      }),
      providesTags: ["links"],
    }),
  }),
});

export const { useLazyGetLinkQuery } = linksApi;
