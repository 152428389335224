import React, { useMemo } from "react";
import { ISlideDto } from "@backend/dto/slideDto";
import { Box, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { OneElementWithTitleSlide } from "./slides/oneElementWithTitle";
import { OneElenentSlide } from "./slides/oneElementSlide";
import { ISlideContent } from "@backend/model/slide";
import "./slideStyles/light.css";
import { theme } from "../../theme";
import { mobileWidth } from "../../styleUtils";
import { TwoColumnsWithTitleSlide } from "./slides/twoColumnsWithTitleSlide";
import { GridWithTitleSlide } from "./slides/gridWithTitleSlide";
import { GridSlide } from "./slides/gridSlide";
import { TwoColumnsSlide } from "./slides/twoColumnsSlide";

export interface ISlideProps extends ISlideDto {
  edit?: boolean;
  setContent: (
    index: number,
    content: string,
    contentType?: string,
    metadata?: string
  ) => void;
  addQuestion?: () => void;
  setReady: () => void;
  imageSize?: boolean;
  setFillMode?: () => void;
  questionUpdated?: (
    index: number,
    question?: string,
    answers?: string[],
    correctAnswer?: number
  ) => void;
  deleteQuestion?: (index: number) => void;
  thumbnailSlide?: boolean;
  organisationId: string;
  password?: string;
}

const SlideContent = (props: {
  slide: ISlideDto;
  slideUpdated: (s: ISlideDto) => void;
  edit: boolean;
  setReady: () => void;
  thumbnailSlide?: boolean;
  organisationId: string;
  password?: string;
}) => {
  const [slide] = useState({ ...props.slide });
  const [content, setContent] = useState<ISlideContent[]>([
    ...props.slide.content,
  ]);

  const { slideUpdated } = props;
  useEffect(() => {
    slideUpdated(
      Object.assign({}, slide, {
        content,
      })
    );
  }, [slide, content, slideUpdated]);

  const onSetContent = useCallback(
    (i: number, val: any, contentType?: string, metadata?: string) => {
      const newContent = [...content];
      newContent[i] = Object.assign({}, newContent[i], {
        data: val,
        contentType: contentType || content[i].contentType,
        metadata: metadata || content[i].metadata,
      });
      setContent(newContent);
    },
    [content]
  );

  const slideProps = useMemo(() => {
    return Object.assign({}, slide, {
      content,
      thumbnailSlide: props.thumbnailSlide,
      edit: props.edit,
      setContent: onSetContent,
      setReady: props.setReady,
      organisationId: props.organisationId,
      password: props.password,
    });
  }, [slide, content, props, onSetContent]);

  switch (slide.type) {
    case "one-element":
      return <OneElenentSlide {...slideProps} />;
    case "one-element-with-title":
      return <OneElementWithTitleSlide {...slideProps} />;
    case "two-columns-with-title":
      return <TwoColumnsWithTitleSlide {...slideProps} />;
    case "grid-with-title":
      return <GridWithTitleSlide {...slideProps} />;
    case "grid":
      return <GridSlide {...slideProps} />;
    case "two-columns":
      return <TwoColumnsSlide {...slideProps} />;
    default:
      return <Box />;
  }
};

export const Slide = (props: {
  slide: ISlideDto;
  slideUpdated: (s: ISlideDto) => void;
  edit: boolean;
  setReady: () => void;
  thumbnailSlide?: boolean;
  marginBottom?: number | string;
  organisationId: string;
  password?: string;
}) => {
  const narrowScreen = useMediaQuery(mobileWidth);

  return (
    <Box
      className={!props.thumbnailSlide ? "slide-light" : "slide-thumbnail"} // disable background color on thumbnail slides
      display={"flex"}
      height={"100%"}
      bgcolor={"#fff"}
      sx={
        props.edit
          ? { width: 0, flexGrow: 1 }
          : props.thumbnailSlide
          ? {}
          : narrowScreen
          ? { overflow: "auto", marginBottom: props.marginBottom }
          : {
              width: `min(calc(((100vh - ${theme.spacing(20)}) * 1.78)), 100%)`, // set width to height * aspect ratio
              minWidth: 100,
              alignSelf: "center",
              height: undefined,
              overflow: "auto",
              aspectRatio: 16 / 9,
              marginBottom: props.marginBottom,
            }
      } // Expand slide width only to the sidebar. Scroll if text area is long.
    >
      <SlideContent {...props} />
    </Box>
  );
};
