// Style utils

import { Theme } from "@mui/material";

// Determine the height for titles in slide configurations.
// In edit-mode, the height is higher, so there's room for toolbar
export const titleHeight = (edit: boolean, theme: Theme) => {
  if(edit) return theme.spacing(14)
  else if (!edit) return theme.spacing(8)
  else return 24 
}

export const numberOfLines = (lines: number) => {
  return {
    display: "-webkit-box",
    WebkitLineClamp: lines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };
};

// 768 seems to be a common breakpoint for mobile devices
// 768-1023 for tablets
// > 1024 for desktop

export const mobileWidth = "(max-width:768px)"

// Height for slides.
export const slideViewHeight = (thumbnail: boolean | undefined, narrowScreen: boolean, theme: Theme, title: boolean, edit: boolean) => {
  if(thumbnail){
    return "100%"
  }
  else if (title) {
    return `calc(${narrowScreen ? "50" : "100"}% - ${titleHeight(edit, theme)})`
  }
  else return narrowScreen ? "50" : "100"
}

export const backgroundImageStyle = (url: any) => 
 url ? {
  height: "100%",
  width: "100%",
  backgroundImage: `url(${url})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
} : {};