import React, { useCallback, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { ISlideProps } from "../slide";
import { theme } from "../../../theme";
import { TextContent } from "../slideTextContent";
import { ImageContent } from "../slideImageContent";
import colors from "../../../colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  backgroundImageStyle,
  mobileWidth,
  titleHeight,
} from "../../../styleUtils";
import { SlideContentSelector } from "../SlideContentSelector";

export const GridWithTitleSlide = (props: ISlideProps) => {
  const narrowScreen = useMediaQuery(mobileWidth);
  const { setReady } = props;

  const titleHeightValue = titleHeight(!!props.edit, theme);

  const [contentsReady, setContentsReady] = useState([
    true, // title is always ready
    false,
    false,
    false,
  ]);

  const setReadyCallback = useCallback((index: number) => {
    setContentsReady((current) => {
      const newContentsReady = [...current];
      newContentsReady[index] = true;
      return newContentsReady;
    });
  }, []);

  useEffect(() => {
    let result = true;
    for (let i = 0; i < contentsReady.length; i++) {
      if (!contentsReady[i]) result = false;
    }
    if (result) setReady();
  }, [setReady, contentsReady]);

  const withBorder = {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderRadius: 1,
    marginRight: 1,
  };

  const withoutBorder = {
    borderWidth: 0,
    marginRight: 1,
  };

  const backgroundImage = backgroundImageStyle(props.content[4]?.data);

  return narrowScreen ? (
    <Box
      sx={Object.assign(
        backgroundImage,
        props.content && props.content[0].contentType !== "unknown"
          ? withoutBorder
          : withBorder,
        {
          width: "100%",
          overflow: "auto",
          margin: 0,
          padding: theme.spacing(1),
        },
        props.thumbnailSlide ? { overflow: "hidden" } : {}
      )}
    >
      <Box>
        <TextContent
          content={(props.content && props.content[0]).data}
          onValueChanged={(val) => props.setContent(0, val)}
          edit={props.edit}
          minimalEditor
          multiline={false}
          title
          height={props.edit ? titleHeightValue : undefined}
          thumbnailSlide={props.thumbnailSlide}
        />
      </Box>
      <SlideContentSelector
        content={props.content && props.content[1]}
        setContent={props.setContent}
        edit={props.edit}
        index={1}
        thumbnail={props.thumbnailSlide}
        onSetReady={setReadyCallback}
        organisationId={props.organisationId}
        password={props.password}
      />
      <div style={{ height: theme.spacing(1) }} />
      <SlideContentSelector
        content={props.content && props.content[2]}
        setContent={props.setContent}
        edit={props.edit}
        index={2}
        thumbnail={props.thumbnailSlide}
        onSetReady={setReadyCallback}
        organisationId={props.organisationId}
        password={props.password}
      />
      <div style={{ height: theme.spacing(1) }} />
      <SlideContentSelector
        content={props.content && props.content[3]}
        setContent={props.setContent}
        edit={props.edit}
        index={3}
        thumbnail={props.thumbnailSlide}
        onSetReady={setReadyCallback}
        organisationId={props.organisationId}
        password={props.password}
      />
      <div style={{ height: theme.spacing(1) }} />
    </Box>
  ) : (
    <Grid
      container
      flex={1}
      flexGrow={1}
      sx={Object.assign(
        backgroundImage,
        props.thumbnailSlide
          ? { overflow: "hidden" }
          : narrowScreen
          ? { overflow: "auto" }
          : { overflow: "auto" }
      )}
      padding={theme.spacing(2)}
    >
      {/* Title */}
      <Grid
        item
        xs={12}
        display={"flex"}
        style={{
          height: narrowScreen ? undefined : titleHeightValue,
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          marginBottom: theme.spacing(1),
        }}
      >
        <TextContent
          content={(props.content && props.content[0]).data}
          onValueChanged={(val) => props.setContent(0, val)}
          edit={props.edit}
          minimalEditor
          multiline={false}
          title
          height={props.edit ? titleHeightValue : undefined}
          thumbnailSlide={props.thumbnailSlide}
        />
      </Grid>
      <Grid
        xs={narrowScreen ? 12 : 6}
        height={`calc(100% - ${titleHeightValue})`}
      >
        {/* Left top */}
        <Grid
          item
          xs={12}
          style={
            // Text works with minimum height,
            // Images require set height
            props.content[1].contentType == "text"
              ? {
                  minHeight: props.thumbnailSlide
                    ? "16vh"
                    : `calc(50% - ${theme.spacing(0)})`,
                }
              : {
                  height: props.thumbnailSlide
                    ? "16vh"
                    : `calc(50% - ${theme.spacing(0)})`,
                    paddingBottom: theme.spacing(1)
                }
          }
        >
          <Box
            height={"100%"}
            sx={
              props.content && props.content[1].contentType !== "unknown"
                ? withoutBorder
                : withBorder
            }
          >
            <SlideContentSelector
              content={props.content && props.content[1]}
              setContent={props.setContent}
              edit={props.edit}
              index={1}
              thumbnail={props.thumbnailSlide}
              onSetReady={setReadyCallback}
              organisationId={props.organisationId}
              password={props.password}
            />
          </Box>
        </Grid>
        {/* Left bottom */}
        <Grid
          item
          xs={12}
          style={
            props.content[2].contentType == "text"
              ? {
                  minHeight: props.thumbnailSlide
                    ? "16vh"
                    : `calc(50% - ${theme.spacing(0)})`,
                }
              : {
                  height: props.thumbnailSlide
                    ? "16vh"
                    : `calc(50% - ${theme.spacing(0)})`,
                }
          }
        >
          <Box
            height={"100%"}
            sx={
              props.content && props.content[2].contentType !== "unknown"
                ? withoutBorder
                : withBorder
            }
          >
            <SlideContentSelector
              content={props.content && props.content[2]}
              setContent={props.setContent}
              edit={props.edit}
              index={2}
              thumbnail={props.thumbnailSlide}
              onSetReady={setReadyCallback}
              organisationId={props.organisationId}
              password={props.password}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        item
        xs={narrowScreen ? 12 : 6}
        style={{
          height: props.thumbnailSlide
            ? "35vh"
            : `calc(100% - ${titleHeightValue})`,
        }}
      >
        {/* Right column */}
        <Box
          height={"100%"}
          sx={
            props.content && props.content[3].contentType !== "unknown"
              ? withoutBorder
              : withBorder
          }
        >
          <SlideContentSelector
            content={props.content && props.content[3]}
            setContent={props.setContent}
            edit={props.edit}
            index={3}
            thumbnail={props.thumbnailSlide}
            onSetReady={setReadyCallback}
            organisationId={props.organisationId}
            password={props.password}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
