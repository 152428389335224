import React, { useCallback, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { ISlideProps } from "../slide";
import { theme } from "../../../theme";
import { TextContent } from "../slideTextContent";
import { ImageContent } from "../slideImageContent";
import colors from "../../../colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import { backgroundImageStyle, mobileWidth, slideViewHeight, titleHeight } from "../../../styleUtils";
import { SlideContentSelector } from "../SlideContentSelector";

export const TwoColumnsWithTitleSlide = (props: ISlideProps) => {
  const narrowScreen = useMediaQuery(mobileWidth);
  const { setReady } = props;

  const [contentsReady, setContentsReady] = useState([true, false, false]);

  const titleHeightValue = titleHeight(!!props.edit, theme)

  const setReadyCallback = useCallback((index: number) => {
    setContentsReady((current) => {
      const newContentsReady = [...current];
      newContentsReady[index] = true;
      return newContentsReady;
    });
  }, []);

  useEffect(() => {
    let result = true;
    for (let i = 0; i < contentsReady.length; i++) {
      if (!contentsReady[i]) result = false;
    }
    //console.log(contentsReady, result);
    if (result) setReady();
  }, [setReady, contentsReady]);

  const withBorder = {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderRadius: 1,
    marginRight: 1,
    alignContent: "center"
  };

  const withoutBorder = {
    borderWidth: 0,
    marginRight: 1,
  };

  const backgroundImage = backgroundImageStyle(props.content[3]?.data)

  return narrowScreen ? (
    <Box
      sx={Object.assign(
        backgroundImage,
        props.content && props.content[0].contentType !== "unknown"
          ? withoutBorder
          : withBorder,
        { width: "100%", margin: 0, overflow: "auto", padding: theme.spacing(1) },
        props.thumbnailSlide ? { overflow: "hidden" } : {}
      )}
    >
      <Box sx={{}}>
        <TextContent
          content={(props.content && props.content[0]).data}
          onValueChanged={(val) => props.setContent(0, val)}
          edit={props.edit}
          minimalEditor
          multiline={false}
          title
          height={props.edit ? titleHeightValue : undefined}
        />
      </Box>
      <SlideContentSelector
        content={props.content && props.content[1]}
        setContent={props.setContent}
        edit={props.edit}
        index={1}
        thumbnail={props.thumbnailSlide}
        onSetReady={setReadyCallback}
        organisationId={props.organisationId}
        password={props.password}
      />
      <div style={{height: theme.spacing(1)}}/>
      <SlideContentSelector
        content={props.content && props.content[2]}
        setContent={props.setContent}
        edit={props.edit}
        index={2}
        thumbnail={props.thumbnailSlide}
        onSetReady={setReadyCallback}
        organisationId={props.organisationId}
        password={props.password}
      />
    </Box>
  ) : (
    <Grid
      container
      flex={1}
      flexGrow={1}
      sx={Object.assign(
        backgroundImage,
        props.thumbnailSlide
          ? { overflow: "hidden" }
          : narrowScreen
          ? { overflow: "auto" }
          : { overflow: "auto" }
      )}
      padding={theme.spacing(2)}
    >
      <Grid
        item
        xs={12}
        display={"flex"}
        style={{
          height: titleHeightValue,
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          marginBottom: theme.spacing(1),
        }}
      >
        <TextContent
          content={(props.content && props.content[0]).data}
          onValueChanged={(val) => props.setContent(0, val)}
          edit={props.edit}
          minimalEditor
          multiline={false}
          title
          height={props.edit ? titleHeightValue : undefined}
          thumbnailSlide={props.thumbnailSlide}
        />
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          minHeight: slideViewHeight(
            props.thumbnailSlide,
            narrowScreen,
            theme,
            true,
            !!props.edit
          ),
        }}
      >
        <Box
          height={"100%"}
          sx={
            props.content && props.content[1].contentType !== "unknown"
              ? withoutBorder
              : withBorder
          }
        >
          <SlideContentSelector
            content={props.content && props.content[1]}
            setContent={props.setContent}
            edit={props.edit}
            index={1}
            thumbnail={props.thumbnailSlide}
            onSetReady={setReadyCallback}
            organisationId={props.organisationId}
            password={props.password}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          minHeight: slideViewHeight(
            props.thumbnailSlide,
            narrowScreen,
            theme,
            true,
            !!props.edit
          ),
        }}
      >
        <Box
          height={"100%"}
          sx={
            props.content && props.content[2].contentType !== "unknown"
              ? withoutBorder
              : withBorder
          }
        >
          <SlideContentSelector
            content={props.content && props.content[2]}
            setContent={props.setContent}
            edit={props.edit}
            index={2}
            thumbnail={props.thumbnailSlide}
            onSetReady={setReadyCallback}
            organisationId={props.organisationId}
            password={props.password}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
